import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Top } from '@components';
// import Image from '../components/image';
// import SEO from '../components/seo';

const IndexPage = ({ location, data }) => (
  <Layout location={location}>
    <Top hero={data.hero.edges} blurb={data.blurb.edges} />
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  {
    hero: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            titleArc
            name
            avatar {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
    blurb: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blurb/" } }
    ) {
      edges {
        node {
          frontmatter {
            blurb1
            blurb2
            title
            titleArc
            resume
            avatar {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;
